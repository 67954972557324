<template>
  <div class="nav">
    <div class="left" @click="goBack">
      <img class="back_img" src="@/assets/img/15.jpg" /> <span>{{ title }}</span>
    </div>
    <img class="right_img" src="@/assets/img/16.jpg" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="less" scoped>
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem 0.24rem 0.1rem 0.42rem;
  background-color: #008cfe;

  .left {
    display: flex;
    align-items: center;
  }

  .back_img {
    height: 0.3rem;
  }

  span {
    font-size: .3rem;
    margin-left: .2rem;
    color: #fff;
  }

  .right_img {
    width: 1.6rem;
    height: 0.6rem;
  }
}
</style>