<template>
  <div>
    <Nav title="请假流程" />
    <div class="top-box">
      <div class="title">请假流程</div>
      <div class="top-item-box">
        <div class="left-item-box">
          <div class="top-item">
            <div class="label">当前处理人：</div>
          </div>
          <div class="top-item">
            <div class="label">当前状态：</div>
            <div class="top-item-value">{{ getRes(info.status) }}</div>
          </div>
          <div class="top-item">
            <div class="label">流转记录：</div>
            <u>查看流转记录</u>
          </div>
        </div>
        <div class="right-item-box">
          <div class="top-item">
            <div class="label">申请时间：</div>
            <div style="font-family: auto; white-space: nowrap">
              {{ info.applicationtime }}
            </div>
          </div>
          <div class="top-item">
            <div class="label">申请者：</div>
            <div class="top-item-value">{{ info.applicant }}</div>
          </div>
          <div class="top-item">
            <div class="label">图形监控：</div>
            <u>查看流程图</u>
          </div>
        </div>
      </div>
      <table class="form">
        <tr>
          <td class="form-left">学生姓名</td>
          <td class="form-right">{{ info.studentname }}</td>
        </tr>
        <tr>
          <td class="form-left">学号</td>
          <td class="form-right">{{ info.studentid }}</td>
        </tr>
        <tr>
          <td class="form-left">院系</td>
          <td class="form-right">{{ info.department }}</td>
        </tr>
        <tr>
          <td class="form-left">班级</td>
          <td class="form-right">{{ info.classes }}</td>
        </tr>
        <tr>
          <td class="form-left">辅导员</td>
          <td class="form-right">{{ info.currenthandler }}</td>
        </tr>
        <tr>
          <td class="form-left">外出时间</td>
          <td class="form-right">{{ info.departuretime }}</td>
        </tr>
        <tr>
          <td class="form-left">预计返回时间</td>
          <td class="form-right">{{ info.expectedreturntime }}</td>
        </tr>
        <tr>
          <td class="form-left">请假类型</td>
          <td class="form-right">{{ getType(info.leavetype) }}</td>
        </tr>
        <tr>
          <td class="form-left">请假事由</td>
          <td class="form-right">{{ info.reasonforleave }}</td>
        </tr>
        <tr>
          <td class="form-left">相关附件</td>
          <td class="form-right" style="color: #97a1aa">添加附件</td>
        </tr>
        <tr>
          <td class="form-left">审核意见</td>
          <td class="form-right">
            <div class="form-last">
              <div class="form-last-item">
                <div class="first">申请人发起</div>
                <div class="second">
                  {{ info.studentname }}/{{ info.classes }}
                </div>
                <div class="third">{{ info.applicationtime }}</div>
              </div>
            </div>
            <div class="split">==========================================</div>
            <div class="agree">同意请假，外出注意安全，按时返校</div>
            <div class="form-last">
              <div class="form-last-item">
                <div class="first">辅导员初审</div>
                <div class="second">
                  {{ info.currenthandler }}/{{ info.department }}
                </div>
                <div class="third">{{ info.endTime }}</div>
              </div>
            </div>
            <div class="split">==========================================</div>
          </td>
        </tr>
      </table>
      <div class="bottom-box">
        <div class="btn-close" @click="$router.back()">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
// 调用方法并输出结果
import Nav from "@/components/nav.vue";
export default {
  components: {
    Nav,
  },
  data() {
    return {
      info: {},
    };
  },
  methods: {
    getType(value) {
      const obj = {
        PersonalLeave: "事假",
        MedicalLeave: "病假",
        Others: "其他",
      };
      return obj[value];
    },
    getRes(value) {
      const obj = {
        Completed: "已办结",
        InProgress: "进行中",
        Pending: "未开始",
      };
      return obj[value];
    },
  },
  mounted() {
    this.info = JSON.parse(sessionStorage.getItem("info"));
  },
};
</script>

<style lang="less" scoped>
.top-box {
  padding-bottom: 1rem;
  overflow: hidden;
  overflow-y: auto;
  .title {
    margin-top: 0.5rem;
    font-size: 0.35rem;
    font-weight: bold;
    text-align: center;
  }

  .top-item-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0.13rem;
    padding-right: 0;
    padding-bottom: 0;

    .left-item-box,
    .right-item-box {
      width: 40%;

      .top-item {
        margin-bottom: 0.15rem;
        display: flex;
        text-align: right;
        font-size: 0.2rem;
        color: #9c9c9c;

        .label {
          width: 41%;
        }

        u {
          color: #cc3300;
        }
      }
    }

    .right-item-box {
      width: 45%;
    }
  }

  .form {
    display: table;
    width: 100%; /* 根据需要设置宽度 */
    border-collapse: collapse; /* 合并边框 */
    tr {
      td {
        line-height: 0.6rem;
        border: 1px solid #3eade5;
        text-align: left; /* 设置文本对齐方式 */
        font-size: 0.2rem;
        color: #333;
        font-weight: bold;
      }
    }

    .form-left {
      vertical-align: middle;
      width: 22%;
      padding-right: 0.1rem;
      background: #ddeaff;
      text-align: right;
    }

    .form-right {
      flex: 1;
      line-height: 0.6rem;
      padding-left: 0.15rem;
      color: #444;
      font-weight: 500;
    }
    .form-last {
      width: 100%;
      display: flex;

      .form-last-item {
        width: 100%;
        display: flex;
        line-height: 0.3rem;

        .first {
          width: 25%;
        }
        .second {
          width: 50%;
          color: #57ab2d;
        }
        .third {
          width: 22%;
          color: #a3a3a3;
        }
      }
    }

    .agree {
      color: #0066cc;
      line-height: 0.3rem;
    }

    .split {
      font-size: 0.22rem;
      line-height: 0.3rem;
    }
  }

  .bottom-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 0.8rem;
    background: #23a3f2;
    padding: 0 0.48rem;

    .btn-close {
      width: 1.4rem;
      line-height: 0.4rem;
      background: #fff;
      border-radius: 3px;
      text-align: center;
    }

    .btn-save {
      width: 1.4rem;
      line-height: 0.4rem;
      background: #2951f5;
      border-radius: 3px;
      text-align: center;
      color: #fff;
    }
  }
}
</style>