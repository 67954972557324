<template>
  <div class="tabbar">
    <div class="item">
      <img src="@/assets/img/11.jpg" alt="" />
      <div>首页</div>
    </div>
    <div class="item">
      <img src="@/assets/img/10.jpg" alt="" />
      <div>我的待办</div>
    </div>
    <div class="item" @click="jumpToDetail">
      <img src="@/assets/img/9.jpg" alt="" />
      <div>我的申请</div>
    </div>
    <div class="item">
      <img src="@/assets/img/1.jpg" alt="" />
      <div>我的</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    jumpToDetail() {
      this.$router.push({ path: "/add", query: { status: "Pending" } });
    },
  },
};
</script>

<style lang="less" scope>
.tabbar {
  height: 0.96rem;
  border-top: 1px solid #a6b0c0;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #a6b0c0;
    font-size: 10px;
    img {
      width: 0.4rem;
      margin-bottom: 0.05rem;
    }
  }
}
</style>