<template>
  <div>
    <Nav title="请假流程" />
    <div class="top-box">
      <div class="title">请假流程</div>
      <div class="top-item-box">
        <div class="left-item-box">
          <div class="top-item">
            <div class="label">当前处理人：</div>
          </div>
          <div class="top-item">
            <div class="label">当前状态：</div>
            <div class="top-item-value">进行中</div>
          </div>
          <div class="top-item">
            <div class="label">流转记录：</div>
            <u>查看流转记录</u>
          </div>
        </div>
        <div class="right-item-box">
          <div class="top-item">
            <div class="label">申请时间：</div>
            <div style="font-family: auto; white-space: nowrap">
              {{ formData.applicationtime }}
            </div>
          </div>
          <div class="top-item">
            <div class="label">申请者：</div>
            <div class="top-item-value">{{ formData.studentname }}</div>
          </div>
          <div class="top-item">
            <div class="label">图形监控：</div>
            <u>查看流程图</u>
          </div>
        </div>
      </div>
      <table class="form">
        <tr class="form-item">
          <td class="form-left">学生姓名</td>
          <td class="form-right">
            <input
              v-model="formData.studentname"
              type="text"
              placeholder="请输入学生姓名"
            />
          </td>
        </tr>
        <tr class="form-item">
          <td class="form-left">学号</td>
          <td class="form-right">
            <input
              readonly
              v-model="formData.studentid"
              type="text"
              placeholder="请输入学号"
            />
          </td>
        </tr>
        <tr class="form-item">
          <td class="form-left">院系</td>
          <td class="form-right">
            <input
              v-model="formData.department"
              type="text"
              placeholder="请输入院系"
            />
          </td>
        </tr>
        <tr class="form-item">
          <td class="form-left">班级</td>
          <td class="form-right">
            <input
              v-model="formData.classes"
              type="text"
              placeholder="请输入班级"
            />
          </td>
        </tr>
        <tr class="form-item">
          <td class="form-left">辅导员</td>
          <td class="form-right">
            <input
              v-model="formData.currenthandler"
              type="text"
              placeholder="请输入辅导员"
            />
          </td>
        </tr>
        <tr class="form-item">
          <td class="form-left">外出时间</td>
          <td class="form-right">
            <input
              v-model="formData.departuretime"
              readonly
              type="text"
              placeholder="请选择时间"
              @click="openPicker(1)"
            />
          </td>
        </tr>
        <tr class="form-item">
          <td class="form-left">预计返回时间</td>
          <td class="form-right">
            <input
              v-model="formData.expectedreturntime"
              readonly
              type="text"
              placeholder="请选择时间"
              @click="openPicker(2)"
            />
          </td>
        </tr>
        <tr class="form-item">
          <td class="form-left">请假类型</td>
          <td class="form-right">
            <div class="radio-box">
              <label>
                <input
                  style="width: unset"
                  type="radio"
                  v-model="formData.leavetype"
                  value="PersonalLeave"
                />
                事假
              </label>
              <label>
                <input
                  style="width: unset"
                  type="radio"
                  v-model="formData.leavetype"
                  value="MedicalLeave"
                />
                病假
              </label>
              <label>
                <input
                  style="width: unset"
                  type="radio"
                  v-model="formData.leavetype"
                  value="Others"
                />
                其他
              </label>
            </div>
          </td>
        </tr>
        <tr class="form-item">
          <td class="form-left">请假事由</td>
          <td class="form-right">
            <textarea v-model="formData.reasonforleave" />
          </td>
        </tr>
        <tr class="form-item">
          <td class="form-left">相关附件</td>
          <td class="form-right" style="color: #97a1aa">添加附件</td>
        </tr>
        <tr class="form-item">
          <td class="form-left">审核意见</td>
          <td class="form-right"></td>
        </tr>
      </table>
      <div class="bottom-box">
        <div class="btn-close" @click="$router.back()">关闭</div>
        <div class="btn-save" @click="submit">办理</div>
      </div>
    </div>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirm"
        @cancel="cancel"
      />
    </van-popup>
  </div>
</template>

<script>
import { formatDate } from "@/utils";
import { saveOfficeFlow } from "@/api/api";
// 调用方法并输出结果
import Nav from "@/components/nav.vue";
export default {
  components: {
    Nav,
  },
  data() {
    return {
      showPicker: false,
      obj: {},
      currentDate: new Date(),
      minDate: new Date(2024, 0, 1),
      maxDate: new Date(2100, 11, 31),
      formData: {
        studentname: "", // 学生姓名
        studentid: sessionStorage.getItem("studentid"), // 学号
        department: "", // 院系
        classes: "", // 班级
        departuretime: "", // 外出时间
        expectedreturntime: "", // 预计返回时间
        leavetype: "", // 请假类型 (事假PersonalLeave/病假MedicalLeave/其他Others)
        reasonforleave: "", // 请假事由
        attachments: "相关附件", // 相关附件
        reviewcomments: "同意请假，外出注意安全，按时返校", // 审核意见
        currenthandler: "", // 当前处理人
        status: "InProgress", // 当前状态 (已结束Completed/进行中InProgress/未开始Pending)
        processlog: "流转记录", // 流转记录
        processgraph: "图形监控", // 图形监控
        applicationtime: formatDate(new Date()), // 申请时间
        applicant: "", // 申请人
      },
      type: 0,
    };
  },
  methods: {
    confirm() {
      const time = formatDate(this.currentDate);
      if (this.type == 1) {
        this.formData.departuretime = time;
      }
      if (this.type == 2) {
        this.formData.expectedreturntime = time;
      }
      this.showPicker = false;
    },
    cancel() {
      this.showPicker = false;
    },
    openPicker(type) {
      this.type = type;
      this.showPicker = true;
    },
    submit() {
      if (!this.formData.studentname) {
        this.$toast("请填写学生姓名");
        return;
      }
      if (!this.formData.department) {
        this.$toast("请填写院系");
        return;
      }
      if (!this.formData.classes) {
        this.$toast("请填写班级");
        return;
      }
      if (!this.formData.currenthandler) {
        this.$toast("请填写辅导员");
        return;
      }
      if (!this.formData.departuretime) {
        this.$toast("请选择外出时间");
        return;
      }
      if (!this.formData.expectedreturntime) {
        this.$toast("请选择返回时间");
        return;
      }
      if (!this.formData.leavetype) {
        this.$toast("请选择请假类型");
        return;
      }
      if (!this.formData.reasonforleave) {
        this.$toast("请填写请假事由");
        return;
      }
      this.formData.applicant = this.formData.studentname;
      saveOfficeFlow(this.formData).then((res) => {
        if (res) {
          this.$toast('请假成功！')
          this.$router.back()
        }
      });
      console.log(this.formData);
    },
  },
  mounted() {
    const status = this.$route.query.status;
  },
};
</script>

<style lang="less" scoped>
.top-box {
  padding-bottom: 1rem;
  overflow: hidden;
  overflow-y: auto;
  .title {
    margin-top: 0.5rem;
    font-size: 0.35rem;
    font-weight: bold;
    text-align: center;
  }

  .top-item-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.2rem 0.13rem;
    padding-right: 0;
    padding-bottom: 0;

    .left-item-box,
    .right-item-box {
      width: 40%;

      .top-item {
        margin-bottom: 0.15rem;
        display: flex;
        text-align: right;
        font-size: 0.2rem;
        color: #9c9c9c;

        .label {
          width: 41%;
        }

        u {
          color: #cc3300;
        }
      }
    }

    .right-item-box {
      width: 45%;
    }
  }

  .form {
    display: table;
    width: 100%; /* 根据需要设置宽度 */
    border-collapse: collapse; /* 合并边框 */
    tr {
      td {
        line-height: 0.6rem;
        border: 1px solid #3eade5;
        text-align: left; /* 设置文本对齐方式 */
        font-size: 0.2rem;
        color: #333;
        font-weight: bold;
      }
    }

    .form-left {
      vertical-align: middle;
      width: 22%;
      padding-right: 0.1rem;
      background: #ddeaff;
      text-align: right;
    }

    .form-right {
      flex: 1;
      line-height: 0.6rem;
      padding-left: 0.15rem;
      color: #444;
      font-weight: 500;

      .radio-box {
        display: flex;
        align-items: center;

        label {
          display: flex;
          align-items: center;
          margin-left: 0.1rem;
        }
      }

      textarea {
        width: 90%;
        border: 0;
      }
    }
    .form-last {
      width: 100%;
      display: flex;

      .form-last-item {
        width: 100%;
        display: flex;
        line-height: 0.3rem;

        .first {
          width: 25%;
        }
        .second {
          width: 50%;
          color: #57ab2d;
        }
        .third {
          width: 22%;
          color: #a3a3a3;
        }
      }
    }

    .agree {
      color: #0066cc;
      line-height: 0.3rem;
    }

    .split {
      font-size: 0.22rem;
      line-height: 0.3rem;
    }
  }

  .bottom-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 0.8rem;
    background: #23a3f2;
    padding: 0 0.48rem;

    .btn-close {
      width: 1.4rem;
      line-height: 0.4rem;
      background: #fff;
      border-radius: 3px;
      text-align: center;
    }

    .btn-save {
      width: 1.4rem;
      line-height: 0.4rem;
      background: #2951f5;
      border-radius: 3px;
      text-align: center;
      color: #fff;
    }
  }

  input {
    height: 100%;
    border: 0;
    width: 90%;
  }

  input[type="text"]::placeholder {
    color: #ccc;
  }
}
</style>