// 时间 + 分钟 = 最新时间
export function addMinutesToDate(dateString, minutes) {
    // 将输入的日期字符串解析为 Date 对象
    const dateParts = dateString.split(' ');
    const [yearMonthDay, time] = dateParts;
    const [year, month, day] = yearMonthDay.split('-').map(Number);
    const [hours, minutesStr] = time.split(':').map(Number);

    // 创建 Date 对象
    const date = new Date(Date.UTC(year, month - 1, day, hours, minutesStr));

    // 加上指定的分钟数
    date.setMinutes(date.getMinutes() + minutes);

    // 格式化为所需的字符串格式
    const yearFormatted = date.getUTCFullYear();
    const monthFormatted = String(date.getUTCMonth() + 1).padStart(2, '0');
    const dayFormatted = String(date.getUTCDate()).padStart(2, '0');
    const hoursFormatted = String(date.getUTCHours()).padStart(2, '0');
    const minutesFormatted = String(date.getUTCMinutes()).padStart(2, '0');

    return `${yearFormatted}-${monthFormatted}-${dayFormatted} ${hoursFormatted}:${minutesFormatted}`;
}
// 随机数
export function getRandomNumberBetween(min, max) {
    // 使用 Math.random() 生成一个 0（包含）到 1（不包含）之间的随机浮点数
    // 然后将其缩放到所需的范围 [min, max)
    // 因为 Math.random() 不包含上限，所以我们通过加 1 来调整范围，并通过 Math.floor() 取整
    // 但由于我们想要包含上限，所以使用 max + 1 作为缩放的上限，并在最后通过条件判断确保不会超过 max
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


export function formatMinutes(minutes) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
 
    if (hours > 0 && remainingMinutes > 0) {
        // 如果小时和分钟都大于0，则返回 "xx小时xx分钟"
        return `${hours}小时${remainingMinutes}分钟`;
    } else if (hours > 0) {
        // 如果只有小时大于0，则返回 "xx小时"
        // 注意：这里可以根据需求决定是否需要显示 "1小时" 而不是 "1小时0分钟"
        // 如果需要这种简化，可以移除下面的 else if 分支，并直接返回这里的字符串
        return `${hours}小时`;
    } else {
        // 如果只有分钟大于0（或者小时和分钟都为0，但这种情况在这个函数里不太可能发生，因为输入是分钟数），则返回 "xx分钟"
        // 注意：这里假设输入不会是负数或零，如果是的话，可能需要添加额外的处理逻辑
        return `${remainingMinutes}分钟`;
    }
    // 注意：原代码中有一个逻辑上的小瑕疵，即当分钟数为60的倍数时，会进入第一个if分支但remainingMinutes为0，
    // 然后会错误地尝试返回 "xx小时0分钟"。上面的代码已经通过调整顺序来避免了这个问题，
    // 因为当hours > 0时，如果remainingMinutes为0，则上面的else if分支会先被触发并返回 "xx小时"。
    // 然而，由于我们的需求是当只有小时时简化输出，所以我们将 "xx小时0分钟" 的情况通过逻辑调整避免了。
}

export function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1，并确保是两位数
    const day = String(date.getDate()).padStart(2, '0'); // 确保是两位数
    const hours = String(date.getHours()).padStart(2, '0'); // 确保是两位数
    const minutes = String(date.getMinutes()).padStart(2, '0'); // 确保是两位数
    const seconds = String(date.getSeconds()).padStart(2, '0'); // 确保是两位数
 
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}