<template>
  <div class="affairs">
    <div>
      <Nav title="事务中心" />
      <div class="search-box">
        <img src="@/assets/img/5.jpg" alt="" />
        <span>请输入事务名称</span>
      </div>
      <div class="tab-box">
        <div class="top-tab-box">
          <div class="tab-item" @click="tabClick('InProgress')">
            审批中
            <div
              :class="['tab', { 'tab-active': currentTab === 'InProgress' }]"
            ></div>
          </div>
          <div class="tab-item" @click="tabClick('Completed')">
            已办结
            <div
              :class="['tab', { 'tab-active': currentTab === 'Completed' }]"
            ></div>
          </div>
        </div>
        <div class="bottom-tab-box">
          <div class="bottom-tab-item">
            事务分类
            <img src="@/assets/img/2.jpg" alt="" />
          </div>
          <div class="bottom-tab-item">
            时间
            <img src="@/assets/img/2.jpg" alt="" />
          </div>
          <div class="bottom-tab-item">
            排序
            <img src="@/assets/img/2.jpg" alt="" />
          </div>
        </div>
      </div>
      <div class="finish-num">当前共有{{list.length}}条{{getRes(currentTab)}}事务</div>
    </div>
    <div class="scroll-view" @scroll="handleScroll">
      <div class="data-item" v-for="item in list" :key="item.id" @click="goToDetail(item)">
        <div class="title">请求流程</div>
        <div class="content">
          <img src="@/assets/img/6.jpg" alt="" />
          <span>完结时间：{{ item.endTime }}</span>
        </div>
        <div class="content">
          <img src="@/assets/img/17.jpg" alt="" />
          <span>总耗时：{{ item.totalTime }}</span>
        </div>
        <div class="content">
          <img src="@/assets/img/8.jpg" alt="" />
          <span>申请结果：{{ getRes(item.status) }}</span>
        </div>
      </div>
    </div>
    <Tabbar />
    <van-dialog
      v-model="showDialog"
      title="请填写你的学号"
      :before-close="beforeClose"
      confirm-button-color="#008cfe"
      @confirm="confirm"
    >
      <div style="padding: 20px 0">
          <van-field
            label-align="center"
            v-model="studentid"
            label="学号"
            placeholder="请输入学号"
          />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { addMinutesToDate, getRandomNumberBetween, formatMinutes  } from "@/utils"
import Nav from "@/components/nav.vue";
import Tabbar from "@/components/tabbar.vue";
import { getOfficeFlowList, saveOfficeFlow } from "@/api/api";

export default {
  data() {
    return {
      currentTab: "Completed",
      showDialog: false,
      studentid: sessionStorage.getItem("studentid"),
      size: 10,
      pages: 0,
      list: [],
    };
  },
  components: {
    Nav,
    Tabbar,
  },
  methods: {
    goToDetail(item) {
      if (item.status === 'Completed') {
        sessionStorage.setItem('info', JSON.stringify(item));
        this.$router.push({path: '/detail', query: { status: item.status }})
      }
    },
    getRes(value) {
      const obj = {
        Completed: '已办结',
        InProgress: '进行中',
        Pending: '未开始'
      }
      return obj[value]
    },
    handleScroll(event) {
      // const bottom =
      //   Math.ceil(event.target.scrollTop + event.target.clientHeight) >=
      //   event.target.scrollHeight;
      // if (bottom && !this.isLoading) {
      //   this.getOfficeFlowList(); // 滚动到底部时加载更多数据
      // }
    },
    confirm() {
      this.getOfficeFlowList();
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        // 验证学号是否填写
        if (!this.studentid) {
          // 如果没有填写学号，则提示用户并阻止弹窗关闭
          this.$toast("请填写学号！");
          done(false); // 阻止关闭
        } else {
          done(); // 允许关闭
          this.$toast("填写成功");
          sessionStorage.setItem("studentid", this.studentid);
        }
      } else {
        // 处理取消按钮或其他关闭操作
        done();
      }
    },
    tabClick(status) {
      this.currentTab = status;
      this.getOfficeFlowList();
    },
    getOfficeFlowList() {
      this.isLoading = true;
      getOfficeFlowList({
        studentid: this.studentid,
        pages: this.pages,
        size: this.size,
        status: this.currentTab
      }).then((res) => {
        this.list = res?.records.map(item => {
          const random = getRandomNumberBetween(60, 200);
          const totalTime = formatMinutes(random);
          const endTime = addMinutesToDate(item.applicationtime, random);
          return { ...item, random, totalTime, endTime }
        });
        this.pages++;
        this.isLoading = false;
      });
    },
  },
  mounted() {
    if (!this.studentid) {
      this.showDialog = true;
    } else {
      this.getOfficeFlowList();
    }
    //     saveOfficeFlow({
    //     // "id": "r4vY5aRcQat0qv62OlkR0",
    //     "studentname": "张三",
    //     "studentid": "58",
    //     "department": "传播与经管学院",
    //     "classes": "2022级财务管理本科01班",
    //     "departuretime": "2024-11-07 08:45:04",
    //     "expectedreturntime": "2024-11-09 90:00:00",
    //     "leavetype": "PersonalLeave",
    //     "reasonforleave": "发烧去医院",
    //     "attachments": "附件",
    //     "reviewcomments": "同意",
    //     "currenthandler": "李四",
    //     "status": "Completed",
    //     "processlog": "流转记录",
    //     "processgraph": "图形监控",
    //     "applicationtime": "2025-11-06 13:41:18",
    //     "applicant": "张三"
    // }).then(res => {
    //       console.log(res);
    //     })
  },
};
</script>

<style lang="less" scoped>
.affairs {
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;

  .search-box {
    margin: 0.16rem;
    height: 0.56rem;
    border-radius: 0.3rem;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 0.3rem;
      margin-right: 0.1rem;
    }

    span {
      color: #c1c1c1;
      font-size: 0.24rem;
    }
  }

  .tab-box {
    background: #fff;
    .top-tab-box {
      display: flex;
      .tab-item {
        position: relative;
        padding: 0.2rem 0;
        font-size: 0.3rem;
        color: #000;
        font-weight: 600;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #f2f2f2;

        .tab-active {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0.7rem;
          height: 0.08rem;
          border-radius: 0.3rem;
          background: #008cfe;
        }
      }
    }
    .bottom-tab-box {
      display: flex;

      .bottom-tab-item {
        width: 33.3%;
        font-size: 0.25rem;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.2rem 0;

        img {
          width: 0.2rem;
          margin-left: 0.2rem;
        }
      }
    }
  }

  .finish-num {
    padding-left: 0.27rem;
    color: #a4b1b9;
    line-height: 0.6rem;
    background: #f3f7f9;
    font-size: 0.25rem;
  }

  .scroll-view {
    padding-bottom: 0.96rem;
    flex: 1;
    overflow-y: scroll;
    .data-item {
      border-radius: 5px;
      border-left: 3px solid #008cfe;
      background: #fff;
      padding: 0.26rem 0.32rem;
      margin: 0.2rem;

      .title {
        color: #000;
        font-size: 0.3rem;
        font-weight: bold;
      }

      .content {
        margin-top: 0.2rem;
        display: flex;
        align-items: flex-end;
        color: #787878;
        font-size: 0.25rem;

        img {
          width: 0.3rem;
          margin-right: 0.05rem;
        }
      }
    }
  }
}
</style>