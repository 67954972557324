import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "@/assets/css/reset.less"
import "@/assets/js/rem"
import 'vant/lib/index.css';
import { Dialog, Field, Toast, Popup, DatetimePicker } from "vant"
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.prototype.$toast = Toast;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
