import request from './request'

export function getOfficeFlowList(params) {
  return request({
    url: '/getOfficeFlowList',
    method: 'post',
    params
  })
}

export function saveOfficeFlow(data) {
  return request({
    url: '/saveOfficeFlow',
    method: 'post',
    data
  })
}