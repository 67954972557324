import Vue from 'vue'
import VueRouter from 'vue-router'
import Affairs from '../views/affairs.vue'
import Detail from '../views/detail.vue'
import Add from '../views/add.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'affairs',
    component: Affairs
  },
  {
    path: '/detail',
    name: 'detail',
    component: Detail
  },
  {
    path: '/add',
    name: 'add',
    component: Add
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
